import React from "react";
import {
  FaRegCommentDots,
  FaSearch,
  FaDesktop,
  FaPhoneAlt,
  FaRegEnvelope,
} from "react-icons/fa";
import Header from "./Header";
import Footer from "./Footer";

function Home() {
  const username = "John Doe"; // Example username
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <div className="relative h-[40vh] md:h-[40vh] lg:h-[40vh]">
  <img
    src="/assets/images/home.webp" // Replace with your image URL
    alt="Banner"
    className="w-full h-full object-cover"
  />
  <div className="absolute inset-0 bg-gray-400 opacity-50"></div>
  <img
    src="/assets/images/EI_CMYK_Master - transparent.png"
    alt="Logo"
    className="absolute top-10 md:top-16 left-4 md:left-28 w-[60%] md:w-[40%] lg:w-80 h-auto"
  />
  <div className="absolute bottom-10 md:bottom-16 right-4 md:right-28 font-bold text-2xl md:text-4xl">
    BERT
  </div>
</div>



      {/* Main Content */}
      <div className="px-4 sm:px-8 md:px-16 lg:px-28 p-6 flex flex-col md:flex-row mt-6 flex-grow">
  {/* Container for the entire section */}
  <div className="container mx-auto px-2 py-3">
    {/* Main content section */}
    <div className="flex flex-col md:flex-row gap-6">
      {/* Left Side: Text Content */}
      <div className="w-full md:w-3/4 bg-white p-4 md:p-2 mt-2 md:mt-0">
        {/* Header Section */}
        <h2 className="text-2xl font-bold mb-4 text-[#1A4D2E] text-center md:text-left">
          Empowering Smarter Energy Decisions
        </h2>

        {/* Description Paragraphs */}
        <p className="mb-2 text-base text-gray-700">
          Welcome to BERT, your all-in-one energy management portal!
          Easily access energy reports to monitor, manage, and optimize
          your usage.
        </p>
        <p className="mb-2 text-base text-gray-700">
          View, download, or share reports, and analyze trends with ease.
          We're here to support you in making informed energy decisions.
        </p>
        <p className="text-base text-gray-700">
          If you have any queries, feel free to reach out to the Energy
          Intelligence Operations team.
        </p>

        {/* Comment Section */}
        <div className="mt-8 flex items-start space-x-4 bg-gray-100 p-6 rounded-lg shadow-md">
          <FaRegCommentDots
            size={60}
            className="text-[#7ABC32] cursor-pointer hover:scale-110 transition-transform"
            title="Comment"
          />
          <p className="text-gray-800 text-base">
            Your site, Lionsgate, is coming out of contract on 1 July. Now
            is a great time to renew your agreement. Please reach out to
            EI Ops to discuss your options and secure your new contract.
          </p>
        </div>
      </div>

      {/* Right Side: Contact Icons and Logos */}
      <div className="w-full md:w-1/4 bg-white p-8 rounded-lg shadow-lg">
        {/* Row for Contact Icons */}
        <div className="flex flex-col items-center space-y-6">
          <div className="flex justify-center gap-8">
            <div className="flex flex-col items-center">
              <FaPhoneAlt
                size={30}
                className="hover:scale-110 transition cursor-pointer text-[#7ABC32]"
                title="Phone"
                aria-label="Phone"
              />
            </div>
            <div className="flex flex-col items-center">
              <FaRegEnvelope
                size={30}
                className="hover:scale-110 transition cursor-pointer text-[#7ABC32]"
                title="Email"
                aria-label="Email"
              />
            </div>
            <div className="flex flex-col items-center">
              <FaDesktop
                size={30}
                className="hover:scale-110 transition cursor-pointer text-[#7ABC32]"
                title="Website"
                aria-label="Website"
              />
            </div>
          </div>

          {/* Divider between icons and logos */}
          <div className="w-full border-t border-gray-300 my-6"></div>

          {/* Row for Logos */}
          <div className="flex flex-col items-center">
            <a href="https://aemo.com.au/en/energysystems/electricity/national-electricitymarket-nem/data-nem/data-dashboardnem" target="_blank" rel="noopener noreferrer">
              <img
                src="/assets/images/aemo-logo.svg" // Replace with your first logo URL
                alt="Logo 1"
                className="w-32 h-auto"
              />
            </a>
          </div>
          <div className="w-full border-t border-gray-300 my-6"></div>

          <div className="flex flex-col items-center">
            <a href="https://www.asxenergy.com.au/" target="_blank" rel="noopener noreferrer">
              <img
                src="/assets/images/asx-logo-blue.svg"
                alt="Logo 2"
                className="w-32 h-auto"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

      <Footer />
    </div>
  );
}

export default Home;
