import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import ForgotPassword from "./components/ForgotPassword";
import Home from "./components/Home";
import BulkReporting from "./components/BulkReporting";
import Header from "./components/Header";
import Footer from "./components/Footer";


function App() {
  return (
    <Router>
    <div className="App">
      <Routes>
      <Route path="/" element={<Login />}  />
      <Route path="/forgot-password" element={<ForgotPassword />}  />
      <Route path="/home" element={<Home />}  />
      <Route path="/header" element={<Header />}  />
      <Route path="/bulk-reporting" element={<BulkReporting />}  />
      </Routes>
    </div>
    </Router>
  );
}

export default App;
