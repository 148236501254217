import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false); // To toggle password visibility

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add validation logic here
  };
  return (
    <div className="flex flex-col min-h-screen">
    <div
      className="flex-1 flex items-center justify-center bg-cover bg-center relative"
      style={{
        backgroundImage: "url('/assets/images/grid.webp')", // Custom background image
        //backgroundColor: "#F7F7F7",
        backgroundColor: "#F7F7F7",
   
        backgroundBlendMode: "overlay", 
        // backgroundBlendMode: "overlay", // Ensures the color blends with the image
      }}
    >
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-lg text-center">
        <img
          src="/assets/images/EI_CMYK_Master - transparent.png" // Custom company logo
          alt="Company Logo"
          className="w-28 mx-auto mb-4"
        />
        <h2 className="text-md font-bold mb-4" style={{ color: "#3A3A3A" }}>
          Energy Intelligencence BERT Portal Password Request
        </h2>

        <form onSubmit={handleSubmit} className="space-y-4 text-left">
          <div>
            <label
              htmlFor="email"
              className="block text-sm text-[#808080] mb-1"
            >
              Email Address
            </label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-[#7ABC32]"
              style={{ color: "#3A3A3A" }}
              required
            />
          </div>

          <div className="text-center">
            <button
              type="submit"
              className="bg-[#7ABC32] text-white py-2 rounded-md transition w-60"
            >
              Send activation / reset
            </button>
          </div>
        </form>

        <p className="text-gray-500 text-sm mt-4">
          <Link to="/"
            style={{ color: "#0571cd", fontSize: "500" }}
            href="#"
            className="underline hover:text-blue-600"
          >
            Remembered your password?
          </Link>
        </p>
      </div>

      
      </div>
      <Footer />
    </div>
  );
}

export default ForgotPassword;
