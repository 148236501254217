import React from 'react';

function Footer() {
  return (
    <footer
      className="bg-[#F7F7F7] w-full py-2 px-6 border-t border-[#E0E0E0] flex flex-col items-center sm:flex-row sm:justify-between sm:items-center mb-0"
    >
      {/* Logo */}
      <div className="mb-2 sm:mb-0">
        <img
          src="/assets/images/EI_CMYK_Master - transparent.png"
          alt="Energy Intel Company Logo"
          className="w-32 sm:w-24"
        />
      </div>

      {/* Help text */}
      <div className="text-center sm:text-right sm:flex sm:flex-col">
        <p className="text-sm">
          Need Help? Call us on
          <span className="text-[#7ABC32] font-bold">
            &nbsp;1300 277 233&nbsp;
          </span>
          or email us at  <a
            href="mailto:ops@energyintel.com.au"
            className="text-[#7ABC32] underline font-bold"
          >
            ops@energyintel.com.au
          </a>
        </p>
        
      </div>
    </footer>
  );
}

export default Footer;
