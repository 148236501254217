import React, { useState } from "react";
import { FaRegFolder, FaDatabase,FaCogs, FaRegFolderOpen, FaRegFileAlt } from "react-icons/fa";

const BulkReporting = () => {
  const [open, setOpen] = useState({
    level1: false,
    level2: false,
    level3: false,
    level4: false,
  });

  const toggle = (level) => {
    setOpen((prev) => ({ ...prev, [level]: !prev[level] }));
  };

  return (
    <div className="w-64 p-3 shadow rounded-md border border-gray-200">
      <div>
        {/* Level 1 */}
        <button
          onClick={() => toggle("level1")}
          className="w-full flex items-center gap-2 px-2 py-1 text-sm text-left hover:bg-gray-100 focus:outline-none"
        >
          <span className="text-xs">{open.level1 ? "▼" : "▶"}</span>
          <span>{open.level1 ? <FaRegFolderOpen /> : <FaRegFolder />}</span>
          <span>Level 1</span>
        </button>
        {open.level1 && (
          <div className="pl-4 border-l-2 border-gray-300">
            {/* Level 2 */}
            <button
              onClick={() => toggle("level2")}
              className="w-full flex items-center gap-2 px-2 py-1 text-sm text-left hover:bg-gray-100 focus:outline-none"
            >
              <span className="text-xs">{open.level2 ? "▼" : "▶"}</span>
              <span>{open.level2 ? <FaDatabase /> : <FaRegFolder />}</span>
              <span>Level 2</span>
            </button>
            {open.level2 && (
              <div className="pl-4 border-l-2 border-gray-300">
                {/* Level 3 */}
                <button
                  onClick={() => toggle("level3")}
                  className="w-full flex items-center gap-2 px-2 py-1 text-sm text-left hover:bg-gray-100 focus:outline-none"
                >
                  <span className="text-xs">{open.level3 ? "▼" : "▶"}</span>
                  <span>{open.level3 ? <FaCogs /> : <FaRegFolder />}</span>
                  <span>Level 3</span>
                </button>
                {open.level3 && (
                  <div className="pl-4 border-l-2 border-gray-300">
                    {/* Level 4 */}
                    <button
                      onClick={() => toggle("level4")}
                      className="w-full flex items-center gap-2 px-2 py-1 text-sm text-left hover:bg-gray-100 focus:outline-none"
                    >
                      <span className="text-xs">{open.level4 ? "▼" : "▶"}</span>
                      <span>{open.level4 ? <FaRegFileAlt /> : <FaRegFolder />}</span>
                      <span>Level 4</span>
                    </button>
                    {open.level4 && (
                      <div className="pl-4 text-sm">
                        <div className="flex items-center gap-2 px-2 py-1 hover:bg-gray-100">
                          <FaRegFileAlt className="text-xs" />
                          <span>Child 1</span>
                        </div>
                        <div className="flex items-center gap-2 px-2 py-1 hover:bg-gray-100">
                          <FaRegFileAlt className="text-xs" />
                          <span>Child 2</span>
                        </div>
                        <div className="flex items-center gap-2 px-2 py-1 hover:bg-gray-100">
                          <FaRegFileAlt className="text-xs" />
                          <span>Child 3</span>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BulkReporting;
