import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { useNavigate } from 'react-router-dom';

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false); // To toggle password visibility
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/home");
    // Add validation logic here
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div
        className="flex-1 flex items-center justify-center bg-cover bg-center relative"
        style={{
          backgroundImage: "url('/assets/images/grid.webp')", // Custom background image
          backgroundColor: "#F7F7F7",
          backgroundBlendMode: "overlay", // Ensures the color blends with the image
          backgroundSize: "cover", // Ensure the image covers the entire container
          backgroundPosition: "center", // Center the image
          backgroundRepeat: "no-repeat", // Prevent image repetition
        }}
      >
        <div
          className="p-8 rounded-lg shadow-md w-full max-w-lg text-center"
          style={{ backgroundColor: "#F7F7F7" }}
        >
          <img
            src="/assets/images/EI_CMYK_Master - transparent.png" // Custom company logo
            alt="Company Logo"
            className="w-28 mx-auto mb-4"
          />
          <h2 className="text-xl font-bold mb-2" style={{ color: "#3A3A3A" }}>
            Energy Intelligence BERT Portal Login
          </h2>

          <form onSubmit={handleSubmit} className="space-y-4 text-left">
            <div>
              <label
                htmlFor="email"
                className="block text-sm text-[#808080] mb-1"
              >
                Email Address
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-[#7ABC32]"
                style={{ color: "#3A3A3A" }}
                required
              />
            </div>

            <div className="relative">
              <label
                htmlFor="password"
                className="block text-sm text-[#808080] mb-1"
              >
                Password
              </label>
              <input
                id="password"
                type={passwordVisible ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-[#7ABC32]"
                style={{ color: "#3A3A3A" }}
                required
              />
              {/* Password visibility toggle button centered */}
              <button
                type="button"
                onClick={() => setPasswordVisible(!passwordVisible)}
                className="absolute inset-y-0 right-0 flex items-center justify-center px-3 cursor-pointer text-gray-400 focus:outline-none focus:text-blue-600"
                style={{ right: "10px" }} // Adjust right positioning for better centering
              >
                <svg
                  className="shrink-0"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  style={{ marginTop: "21px", color: "#0571cd" }} // Apply margin-top here
                >
                  <path
                    className={passwordVisible ? "hidden" : ""}
                    d="M9.88 9.88a3 3 0 1 0 4.24 4.24"
                  />
                  <path
                    className={passwordVisible ? "hidden" : ""}
                    d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"
                  />
                  <path
                    className={passwordVisible ? "hidden" : ""}
                    d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"
                  />
                  <line
                    className={passwordVisible ? "hidden" : ""}
                    x1={2}
                    x2={22}
                    y1={2}
                    y2={22}
                  />
                  <path
                    className={passwordVisible ? "" : "hidden"}
                    d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"
                  />
                  <circle
                    className={passwordVisible ? "" : "hidden"}
                    cx={12}
                    cy={12}
                    r={3}
                  />
                </svg>
              </button>
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="bg-[#7ABC32] text-white py-2 rounded-md transition w-60"
              >
                Login
              </button>
            </div>
          </form>

          <p className="text-gray-500 text-sm mt-4">
            <Link
              to="/forgot-password"
              style={{ color: "#0571cd", fontSize: "500" }}
              className="underline hover:text-blue-600"
            >
              Forgot password?
            </Link>
            &nbsp;|&nbsp;
            <Link
              to="/"
              style={{
                color: "#0571cd",
                fontSize: "500",
              }}
              className="underline hover:text-blue-600"
            >
              First time user?
            </Link>
          </p>
        </div>
      </div>
      <Footer /> {/* Add the Footer component */}
    </div>
  );
}

export default Login;
