import React from 'react';
import {
  FaHome,
  FaFileAlt,
  FaUser,
  FaChevronDown,
  FaSearch,
} from 'react-icons/fa';

function Header() {
  const username = 'John Doe';

  return (
    <div>
      {/* Header */}
      <header className="bg-[#F7F7F7] h-16 py-2 px-4 sm:px-8 md:px-16 lg:px-28 flex flex-wrap items-center justify-between">
        {/* Left Section: Search Box (Hidden on Small Screens) */}
        <div className="hidden sm:flex items-center w-full md:w-auto mb-2 md:mb-0">
          <button
            className="bg-[#7ABC32] text-white px-3 py-1.5 rounded-l-md"
            style={{ borderRight: '1px solid #F7F7F7' }}
          >
            Search by Account
          </button>
          <div className="relative flex-1">
            <input
              type="text"
              placeholder="Account Number or NMI/Meter"
              className="w-full sm:w-96 px-3 py-1.5 border rounded-r-md focus:outline-none focus:ring-1 focus:ring-[#7ABC32] focus:py-1"
              style={{ fontStyle: 'italic' }}
            />
            {/* Search Icon */}
            <FaSearch
              className="absolute right-3 top-2.5 text-blue-500 cursor-pointer"
              size={18}
              title="Search"
            />
          </div>
        </div>

        {/* Right Section: Icons */}
        <div className="flex items-center justify-between w-full sm:w-auto space-x-4 md:space-x-6">
          {/* Icons */}
          <div className="flex justify-center space-x-4 md:space-x-6">
            <FaHome
              size={20}
              className="hover:scale-110 transition cursor-pointer"
              style={{ color: '#7ABC32' }}
              title="Home"
            />
            <FaFileAlt
              size={18}
              className="hover:scale-110 transition cursor-pointer"
              style={{ color: '#7ABC32' }}
              title="Files"
            />
            <FaUser
              size={18}
              className="hover:scale-110 transition cursor-pointer"
              style={{ color: '#7ABC32' }}
              title="User"
            />
          </div>

          {/* Username and Dropdown */}
          <div className="flex items-center space-x-2">
            <span className="text-gray-800 font-medium">{username}</span>
            <FaChevronDown
              size={16}
              className="hover:scale-110 transition cursor-pointer"
              style={{ color: 'rgb(59 130 246)' }}
            />
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
